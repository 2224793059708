import React, {Component} from 'react'
import './CertificateSelection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoppingBasket} from '@fortawesome/free-solid-svg-icons'
import InsideOutLogo from './elements/InsideOutLogo'

class CertificateSelection extends Component {

    state = {}

    render = () => {
        const blocks = this.props.certificateOptions.map((option, i) =>
            <div
                key={`certificate-selection-${i}`}
                className={"certificate-selection-block"}
                onClick={() => this.props.setOption(option)}
            >
                <span className={"certificate-selection-block-icon"}>
                    {option.icon != null ? <FontAwesomeIcon icon={option.icon} /> : <img src={option.image} alt={option.name} /> }
                </span>
                <h4 className={"certificate-selection-block-title"}>{option.name}</h4>
                <h5 className={"certificate-selection-block-price"}>{(option.price / 100).toFixed(2)} PLN</h5>
            </div>
        )

        return (
            <div className={"certificate-selection-wrapper"}>
                <InsideOutLogo/>
                {blocks}
                <div
                    key={`certificate-selection-shop`}
                    className={"certificate-selection-block"}
                    onClick={() => window.open("https://sklep.fundacjainsideout.pl", "_blank")}
                >
                <span className={"certificate-selection-block-icon"}>
                    <FontAwesomeIcon icon={faShoppingBasket} />
                </span>
                    <h4 className={"certificate-selection-block-title"}>Zobacz inne produkty</h4>
                    <h5 className={"certificate-selection-block-price"}>Przejdź do sklepu Fundacji Inside Out</h5>
                </div>
            </div>
        )
    }
}

export default CertificateSelection