import {personalDataApiUrl} from './urls'
import DatasourceException from './DatasourceException'

class PersonalDataDatasource {
    static fetchData = (id) =>
        fetch(`${personalDataApiUrl}?id=${id}`, {
            credentials: "include"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new DatasourceException("Data not found")
                }
            })

    static saveMarketing = (id, value, token) =>
        fetch(`${personalDataApiUrl}?id=${id}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "X-CSRF-Token": token
            },
            body: JSON.stringify({
                marketing: value
            })
        })

    static deleteData = (id, token) =>
        fetch(`${personalDataApiUrl}?id=${id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "X-CSRF-Token": token
            }
        })
}

export default PersonalDataDatasource