import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import './Menu.css'
import {faSignOutAlt, faStamp} from '@fortawesome/free-solid-svg-icons'
import EventList from './EventList'
import User from './datasource/User'

class Menu extends Component {
    state = {}

    logout = () => {
        User.logout().then(() => {
            this.props.setUser(false);
        })
    }

    render = () => {
        return (
            <div className={"menu-wrapper"}>
                <div
                    className={"menu-block"}
                    onClick={() => this.props.setOption(EventList)}>
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faCalendarAlt}/>
                    </span>
                    <h4 className={"menu-item-title"}>Wydarzenia</h4>
                </div>
                <div
                    className={"menu-block"}
                    onClick={() => this.props.setOption()}>
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faStamp}/>
                    </span>
                    <h4 className={"menu-item-title"}>Zaświadczenia</h4>
                </div>
                <div
                    className={"menu-block"}
                    onClick={() => this.logout()}>
                    <span className={"menu-icon"}>
                        <FontAwesomeIcon icon={faSignOutAlt}/>
                    </span>
                    <h4 className={"menu-item-title"}>Wyloguj</h4>
                </div>
            </div>
        )
    }
}

export default Menu