import React, {Component} from 'react'
import './RadioGroup.css'

class RadioGroup extends Component {

    state = {
        currentOption: null
    }

    changeValue = (event) => {
        if (this.disabled()) return

        this.setState({
            currentOption: event.target.value
        })

        if (this.props.setValue) {
            this.props.setValue(event.target.value)
        }
    }

    disabled = () => typeof this.props.disabled != "undefined" ? this.props.disabled : false

    render = () => {
        return <div className={"radio-group-wrapper " + (!this.props.correct ? "fix-me" : "")}>
            <fieldset id={this.props.group}>
                {this.props.options.map((option, i) =>
                    <div className={"radio-wrapper"} key={`radio-group-${this.props.group}-${i}`}>
                        <input id={`radio-${this.props.group}-${i}`}
                               type={"radio"}
                               value={option}
                               name={this.props.group}
                               checked={this.state.currentOption === option}
                               onChange={this.changeValue}
                               disabled={this.disabled()}
                        />
                        <label htmlFor={`radio-${this.props.group}-${i}`} className={"description"}>{option}</label>
                    </div>
                )}
            </fieldset>
        </div>
    }
}

export default RadioGroup