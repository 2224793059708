import React, {Component} from 'react'
import Events from './datasource/Events'
import './EventList.css'

class EventList extends Component {
    static readableName = "Wydarzenia"

    state = {
        events: []
    }

    componentDidMount = () => {
        Events.fetchAll().then((events) => {
            this.setState({ events })
        })
    }

    render = () =>
        <div className={"event-list"}>
            <div className={"table-wrapper"}>
                <table>
                    <tr className={"table-header"}>
                        <td>id</td>
                        <td>Nazwa</td>
                        <td>Data</td>
                        <td>Czas trwania zapisów</td>
                    </tr>
                    {this.events()}
                </table>
            </div>
        </div>

    events = () => this.state.events.map((event) =>
            <tr>
                <td>{event.id}</td>
                <td>{event.name}</td>
                <td>{event.event_date}</td>
                <td>{event.date_start} - {event.date_end}</td>
            </tr>
        )
}

export default EventList