import React, {Component} from 'react'
import './PriceInfo.css'

class PriceInfo extends Component {
    render = () => {
        return <div className={"price-info-wrapper"}>
            <div className={"price-info-label"}>Wysokość cegiełki</div>
            <div className={"price-info-value"}><span className={"literal-value"}>{(this.props.price / 100).toFixed(2)}</span> PLN {this.props.shipping ? `+ wysyłka ${this.props.shipping} PLN` : '' }</div>
            <div className={"break"} />
            <div className={"shipping-info"}>{this.props.shippingInfo ? this.props.shippingInfo : ""}</div>
        </div>
    }
}

export default PriceInfo