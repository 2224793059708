import React, {Component} from 'react'
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './LoadingIcon.css'

class LoadingIcon extends Component {
    render = () => {
        return <div className={"loading-icon-wrapper"}>
            <FontAwesomeIcon className={"loading-icon"} icon={faCircleNotch} spin={true} />
        </div>
    }
}

export default LoadingIcon