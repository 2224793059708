import {apiUrl} from '../../datasource/urls'
import DatasourceException from '../../datasource/DatasourceException'

class User {
    static fetchCurrent = () =>
        fetch(`${apiUrl}admin/user.php`, {
            credentials: "include"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json().then((json) => json["user"])
                }

                throw new DatasourceException("not logged")
            })

    static logout = () =>  fetch(`${apiUrl}admin/user.php`, {
        credentials: "include",
        method: "DELETE"
    })

    static login = (username, password) => fetch(`${apiUrl}admin/user.php`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
            username, password
        })
    })
        .then((response) => {
            if (response.ok) {
                return username
            }

            throw new DatasourceException("not logged")
        })
}

export default User