import {certificateApiUrl} from './urls'
import DatasourceException from './DatasourceException'

class FormSubmit {
    static getToken() {
        return fetch(`${certificateApiUrl}`, {
            credentials: "include"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new DatasourceException("Cannot fetch token")
                }
            })
            .then((response) => {
                return response["token"]
            })
    }

    static submitCertificateRequest(
        event_id,
        token,
        type,
        pronoun,
        name,
        email,
        telephone = null,
        gdpr,
        marketing_campaign,
        street = null,
        house_number = null,
        post_code = null,
        city = null,
        invoice = false,
        nip = null,
        company_info = null,
        product_id = null
    ) {
        return fetch(`${certificateApiUrl}`, {
            method: "POST",
            credentials: "include",
            headers: {
                "X-CSRF-Token": token
            },
            body: JSON.stringify({
                event_id,
                type,
                female: pronoun.toLowerCase().endsWith("i"),
                name,
                email,
                telephone,
                gdpr,
                marketing_campaign,
                street,
                house_number,
                post_code,
                city,
                product_id,
                invoice: invoice ? { nip, company_info } : null
            })
        })
            .then((result) => {
                if (result.ok) {
                    return result.json()
                } else {
                    throw new DatasourceException("Cannot issue certificate");
                }
            })
            .then((response) => {
                return response["issue_url"]
            })
    }
}

export default FormSubmit