import Payment from '../models/Payment'
import {paymentApiUrl} from './urls'
import DatasourceException from './DatasourceException'

class PaymentDatasource {
    static received = false

    static fetchPayment = (id) => {
        return fetch(`${paymentApiUrl}?id=${id}`)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new DatasourceException("Payment not found")
                }
            })
            .then((response) => new Payment(
                response["id"],
                response["event_name"],
                response["state"] === "OK",
                response["state"] === "REJECTED",
            ))
    }
}

export default PaymentDatasource