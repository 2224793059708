import React, {Component} from 'react'
import './SubmitButton.css'

class SubmitButton extends Component {

    submit = (event) => {
        event.preventDefault()
        if (this.props.onSubmit) {
            this.props.onSubmit()
        }
    }

    render = () => {
        return <div className={"submit-wrapper"}>
            <input type={"submit"} value={this.props.label} onClick={this.submit} />
        </div>
    }
}

export default SubmitButton