class CertificateOption {
    constructor(name, icon, image, component, price, product) {
        this.name = name
        this.icon = icon
        this.image = image
        this.component = component
        this.price = price
        this.product = product
    }
}

export default CertificateOption