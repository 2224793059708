import React, {Component} from 'react'
import './Checkbox.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import {faSquare} from '@fortawesome/free-regular-svg-icons'

class Checkbox extends Component {
    state = {
        checked: false
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.checked) {
            state.checked =  props.checked
        }
        
        return state
    }

    changeValue = () => {
        if (this.disabled()) return

        const checked = this.state.checked
        this.setState({
            checked: !checked
        })

        if (this.props.setValue) {
            this.props.setValue(!checked)
        }
    }

    disabled = () => typeof this.props.disabled != "undefined" ? this.props.disabled : false

    render = () => {
        const classes = [
            "checkbox-wrapper",
            (this.props.required ? "required" : ""),
            (this.state.checked ? "checked" : ""),
            (!this.props.correct ? "fix-me" : ""),
        ]

        return <div className={classes.join(" ")}>
            <FontAwesomeIcon icon={faCheckSquare} className={"check"} onClick={this.changeValue} />
            <FontAwesomeIcon icon={faSquare} className={"empty"} onClick={this.changeValue} />
            <input type={"checkbox"}
                   id={this.props.id}
                   checked={this.state.checked}
                   onChange={this.changeValue}
                   disabled={this.disabled()}
            />
            <label htmlFor={this.props.id}>{this.props.label}<span className={"required-indicator"}>*</span></label>
        </div>
    }
}

export default Checkbox