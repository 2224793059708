import React, {Component} from 'react'
import './RequiredFieldInfo.css'

class RequiredFieldInfo extends Component {
    render = () => {
        return <div className={"required-field-info"}>
            Pola oznaczone * są wymagane.
        </div>
    }
}

export default RequiredFieldInfo