import React, {Component} from 'react'
import InputField from './form/InputField'
import './Form.css'
import RadioGroup from './form/RadioGroup'
import Checkbox from './form/Checkbox'
import GDPRInfo from './elements/GDPRInfo'
import RequiredFieldInfo from './elements/RequiredFieldInfo'
import SubmitButton from './form/SubmitButton'
import PriceInfo from './elements/PriceInfo'
import {alwaysTrue, emailValidation, notEmpty, oneOf, validateAll} from './FormUtils'
import FormSubmit from '../datasource/FormSubmit'
import LoadingIcon from './elements/LoadingIcon'
import InsideOutSign from './elements/InsideOutSign'

class PaperCertificateForm extends Component {
    state = {
        duringSubmit: false,
        form: {
            pronounRadio: {
                value: "",
                correct: true,
                validation: oneOf(["Pan", "Pani"])
            },
            name: {
                value: "",
                correct: true,
                validation: notEmpty
            },
            email: {
                value: "",
                correct: true,
                validation: emailValidation
            },
            emailConfirmation: {
                value: "",
                correct: true,
                validation: (value) => !!value && value === this.state.form.email.value
            },
            phone: {
                value: "",
                correct: true,
                validation: alwaysTrue
            },
            street: {
                value: "",
                correct: true,
                validation: notEmpty
            },
            houseNumber: {
                value: "",
                correct: true,
                validation: notEmpty
            },
            postCode: {
                value: "",
                correct: true,
                validation: notEmpty
            },
            city: {
                value: "",
                correct: true,
                validation: notEmpty
            },
            gdpr: {
                value: false,
                correct: true,
                validation: notEmpty
            },
            marketing: {
                value: false,
                correct: true,
                validation: alwaysTrue
            }
        }
    }

    setValue = (element, value) => {
        const form = this.state.form
        form[element].value = value
        form[element].correct = form[element].validation(value)

        this.setState({
            form
        })
    }

    submitForm = () => {
        const form = this.state.form
        const isValid = validateAll(form)

        this.setState({
            form
        }, () => {
            if (isValid) {
                this.fetchFormResult(form)
            }
        })
    }

    fetchFormResult = (form) => {
        this.setState({
            duringSubmit: true
        }, () => {
            FormSubmit.submitCertificateRequest(
                this.props.insideOutEvent.id,
                this.state.token,
                "paper",
                form.pronounRadio.value,
                form.name.value,
                form.email.value,
                form.phone.value,
                form.gdpr.value,
                form.marketing.value,
                form.street.value,
                form.houseNumber.value,
                form.postCode.value,
                form.city.value
            ).then((result) => {
                if (result) {
                    window.location = result
                }
            })
        })
    }
    componentDidMount = () => {
        FormSubmit.getToken().then((token) => {
            this.setState({
                token
            })
        })
    }

    render = () => {
        return <div className={"paper-certificate-form"}>
            <form className={"certificate-form"}>
                <div className={"form-column form-column-stretched"}>
                    <InsideOutSign/>
                </div>
                <div className={"form-column"}>
                    <div className={"form-section"}>
                        <p className={"form-section-description"}>Dane, które pojawią się na zaświadczeniu</p>
                        <RadioGroup options={["Pan", "Pani"]}
                                    group={"pronounRadio"}
                                    correct={this.state.form["pronounRadio"].correct}
                                    setValue={(value) => this.setValue("pronounRadio", value)}
                                    disabled={this.state.duringSubmit}
                        />
                        <InputField label={"Imię i nazwisko"}
                                    required={true}
                                    correct={this.state.form["name"].correct}
                                    setValue={(value) => this.setValue("name", value)}
                                    disabled={this.state.duringSubmit}
                        />
                    </div>

                    <InputField label={"E-mail"} type={"email"} required={true}
                                correct={this.state.form["email"].correct}
                                setValue={(value) => this.setValue("email", value)}
                                disabled={this.state.duringSubmit}
                    />
                    <InputField label={"Potwierdź e-mail"} type={"email"} required={true}
                                correct={this.state.form["emailConfirmation"].correct}
                                setValue={(value) => this.setValue("emailConfirmation", value)}
                                disabled={this.state.duringSubmit}
                    />
                    <InputField label={"Telefon"}
                                correct={this.state.form["phone"].correct}
                                setValue={(value) => this.setValue("phone", value)}
                                disabled={this.state.duringSubmit}
                    />
                </div>

                <div className={"form-column"}>
                    <div className={"form-section"}>
                        <p className={"form-section-description"}>Dane do wysyłki</p>
                        <InputField label={"Ulica"} required={true}
                                    correct={this.state.form["street"].correct}
                                    setValue={(value) => this.setValue("street", value)}
                                    disabled={this.state.duringSubmit}
                        />
                        <InputField label={"Nr domu i mieszkania"} required={true}
                                    correct={this.state.form["houseNumber"].correct}
                                    setValue={(value) => this.setValue("houseNumber", value)}
                                    disabled={this.state.duringSubmit}
                        />
                        <InputField label={"Kod pocztowy"} required={true}
                                    correct={this.state.form["postCode"].correct}
                                    setValue={(value) => this.setValue("postCode", value)}
                                    disabled={this.state.duringSubmit}
                        />
                        <InputField label={"Miejscowość"} required={true}
                                    correct={this.state.form["city"].correct}
                                    setValue={(value) => this.setValue("city", value)}
                                    disabled={this.state.duringSubmit}
                        />

                    </div>
                </div>

                <div className={"form-column form-column-stretched"}>
                    <div className={"form-section"}>
                        <p className={"form-section-description"}>Zgody</p>
                        <Checkbox id={"rodo"}
                                  label={"Wyrażam zgodę na przetwarzanie moich danych osobowych przez Fundację Inside Out"}
                                  required={true}
                                  correct={this.state.form["gdpr"].correct}
                                  setValue={(value) => this.setValue("gdpr", value)}
                                  disabled={this.state.duringSubmit}
                        />
                        <Checkbox id={"marketing-campaign"}
                                  label={"Chcę otrzymać od Fundacji Inside Out newslettera z inspiracjami informacjami o wydarzeniach"}
                                  correct={this.state.form["marketing"].correct}
                                  setValue={(value) => this.setValue("marketing", value)}
                                  disabled={this.state.duringSubmit}
                        />
                    </div>
                </div>

                <div className={"form-column form-column-stretched"}>
                    <PriceInfo price={this.props.insideOutEvent ? this.props.insideOutEvent.price.paper : 0}
                        shippingInfo={"Wysyłka w przeciągu 7 dni roboczych"}
                    />
                    {this.state.duringSubmit ? <LoadingIcon/> : <SubmitButton label={"Przejdź do płatności"} onSubmit={this.submitForm}/>}
                    <RequiredFieldInfo/>
                </div>
                <div className={"form-column form-column-stretched"}>
                    <GDPRInfo insideOutEvent={this.props.insideOutEvent} />
                </div>
            </form>
        </div>
    }
}

export default PaperCertificateForm