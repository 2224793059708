import InsideOutEvent from '../models/InsideOutEvent'
import CertificatePrice from '../models/CertificatePrice'
import {eventApiUrl, productsApiUrl} from './urls'
import DatasourceException from './DatasourceException'
import Product from '../models/Product'

class EventDatasource {
    static fetchEvent = (id) => {
        return fetch(`${eventApiUrl}?id=${id}`)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new DatasourceException("Event not found")
                }
            })
            .then((response) => {
                const event = new InsideOutEvent(
                    response["id"],
                    response["name"],
                    response["event_date"],
                    new CertificatePrice(response["digital_price"], response["paper_price"]),
                    []
                )
                return EventDatasource.fetchProducts(event)
            })
    }

    static fetchProducts = (event) => {
        return fetch(`${productsApiUrl}?id=${event.id}`)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return new Promise((resolve) => resolve([]))
                }
            })
            .then((response) => {
                const products = response.map(item => new Product(item["product_id"], item["product_name"], item["product_price"], item["product_image"]))
                event.products = products
                return event
            })
    }
}

export default EventDatasource