import React, {Component} from 'react'
import './App.css'
import CertificateSelection from './components/CertificateSelection'
import CertificateOption from './models/CertificateOption'
import {faAt, faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons'
import DigitalCertificateForm from './components/DigitalCertificateForm'
import Toolbar from './components/Toolbar'
import PaperCertificateForm from './components/PaperCertificateForm'
import EventDatasource from './datasource/EventDatasource'
import {Redirect, withRouter} from "react-router-dom"
import Sorry from './Sorry'
import ProductForm from './components/ProductForm'

class App extends Component {

    state = {
        showBody: true,
        option: null,
        event: null
    }

    setCertificateOption = (option) => {
        const changeState = (statesQueue) => {
            this.setState(statesQueue.shift(), () => {
                if (statesQueue.length > 0) {
                    setTimeout(() => {
                        changeState(statesQueue)
                    }, 200)
                }
            })
        }

        changeState([{showBody: false}, {option: option}, {showBody: true}])
    }

    componentDidMount = () => {
        const id = this.props.match.params.id

        EventDatasource.fetchEvent(id)
            .then((event) => {
                const certificateOptions = []
                event.products.forEach(product => {
                    certificateOptions.push(
                        new CertificateOption(product.name, null, product.image, ProductForm, product.price, product)
                    )
                })
                certificateOptions.push(...[
                    event.price.digital ? new CertificateOption("Elektroniczne zaświadczenie", faAt, null, DigitalCertificateForm, event.price.digital) : null,
                        event.price.paper ? new CertificateOption("Papierowe zaświadczenie", faEnvelopeOpen, null, PaperCertificateForm, event.price.paper) : null
                    ].filter(it => it != null)
                )

                this.setState({
                    event,
                    certificateOptions
                })
            })
            .catch(() => {
                this.setState({
                    wrongEvent: true
                })
            })
    }

    render = () => {
        if (this.state.wrongEvent) {
            return <Sorry />
        }

        if (this.state.event == null) {
            return <div className={"loading-placeholder"}>
                <p>Ładowanie...</p>
            </div>
        }

        return this.eventPage()
    }

    eventPage = () =>
        <div className="inside-out-form">
            <header className="inside-out-form-header">
                <h1 className={"inside-out-form-title"}>
                    Zgłoszenie o wydanie zaświadczenia
                </h1>
                <h2 className={"inside-out-form-event"}>
                    {this.state.event.name}
                </h2>
            </header>
            <Toolbar
                goBackLabel={""}
                currentLabel={this.state.option != null ? this.state.option.name : ""}
                hidden={this.state.option == null}
                goBack={() => this.setCertificateOption(null)}
            />
            <div className={"inside-out-form-body " + (this.state.showBody ? "show" : "hide")}>
                {this.formBody()}
            </div>
        </div>

    formBody = () => {
        const state = this.state
        if (state.option == null) {
            return <CertificateSelection
                certificateOptions={state.certificateOptions}
                setOption={this.setCertificateOption}
            />
        } else {
            return <state.option.component
                key={state.option.name}
                insideOutEvent={state.event}
                product={state.option.product}
            />
        }
    }
}

export default withRouter(App)
