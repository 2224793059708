import React, {Component} from 'react'
import logo from '../../assets/insideout_full.png'
import './InsideOutLogo.css'

class InsideOutLogo extends Component {
    render = () =>
        <div className={"inside-out-logo-wrapper"}>
            <img src={logo} className={"inside-out-logo"} alt={"Logo Fundacji InsideOut"}/>
        </div>
}

export default InsideOutLogo