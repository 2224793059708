import React, {Component} from 'react'
import '../components/Form.css'
import InputField from '../components/form/InputField'
import LoadingIcon from '../components/elements/LoadingIcon'
import SubmitButton from '../components/form/SubmitButton'
import User from './datasource/User'

class Login extends Component {
    state = {
        login: null,
        password: null,
        duringSubmit: false
    }

    submitForm = () => {
        this.setState({
            duringSubmit: true
        }, () => {
            User.login(this.state.login, this.state.password)
                .then((user) => {
                    this.props.setUser(user)
                })
                .catch(() => {
                    this.setState({
                        duringSubmit: false
                    })
                })
        })
    }

    render = () => {
        return <div>
            <InputField label={"Login"}
                        correct={true}
                        setValue={(value) => this.setState({ login: value })}
                        disabled={this.state.duringSubmit}
            />
            <InputField label={"Hasło"}
                        correct={true}
                        type={"password"}
                        setValue={(value) => this.setState({ password: value })}
                        disabled={this.state.duringSubmit}
            />

            {this.state.duringSubmit ? <LoadingIcon/> : <SubmitButton label={"Zaloguj"} onSubmit={this.submitForm}/>}
        </div>
    }
}

export default Login