import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import './App.css'
import LoadingIcon from './components/elements/LoadingIcon'
import PaymentDatasource from './datasource/PaymentDatasource'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import InsideOutLogo from './components/elements/InsideOutLogo'
import Payment from './models/Payment'
import ResendDatasource from './datasource/ResendDatasource'

class Confirmation extends Component {
    state = {
        payment: null,
        paymentRefreshInterval: null,
        resent: null,
        resentError: false
    }

    componentDidMount = () => {
        this.setFetchPaymentTimeout(0)
    }

    fetchPayment = (callback) => {
        const id = this.props.match.params.id

        PaymentDatasource.fetchPayment(id)
            .then((payment) => {
                this.setState({
                    payment
                }, () => {
                    if (callback) {
                        callback()
                    }
                })
            })
            .catch((error) => {
                this.setState({
                    payment: new Payment(
                        id,
                        "Nieznane wydarzenie",
                        false,
                        Number.parseInt(id) === 0
                    ),
                    error: true
                })
            })
    }

    setFetchPaymentTimeout = (timeout) => {
        const start = new Date().getTime()
        setTimeout(() => {
            this.fetchPayment(() => {
                const stop = new Date().getTime()
                const newTimeout = Math.abs(stop - start) + 5000
                if (!this.wasPaymentReceived()) {
                    this.setFetchPaymentTimeout(newTimeout)
                }
            })
        }, timeout)
    }

    wasPaymentReceived = () => {
        return this.state.payment && (this.state.payment.received || this.state.payment.rejected)
    }

    wasPaymentRejected = () => {
        return this.wasPaymentReceived() && this.state.payment.rejected
    }

    resend = () => {
        this.setState({
            resent: false
        })
        ResendDatasource.resend(this.state.payment.id)
            .then((response) => {
                this.setState({
                    resent: true,
                    resentError: !response.ok
                })
            })
    }

    render = () => {
        return (
            this.state.payment == null ? <div className={"loading-placeholder"}>
                <p>Ładowanie...</p>
            </div> : this.eventPage()
        )
    }

    eventPage = () =>
        <div className="inside-out-form">
            <header className="inside-out-form-header">
                <h1 className={"inside-out-form-title"}>
                    Zgłoszenie o wydanie zaświadczenia
                </h1>
                <h2 className={"inside-out-form-event"}>
                    {this.state.payment.name}
                </h2>
            </header>
            <div className={"inside-out-form-body payment-confirmation show"}>
                <InsideOutLogo/>
                {this.wasPaymentReceived() ?
                    (this.wasPaymentRejected() ? this.paymentRejected() : this.paymentReceived())
                    : this.paymentPending()}
            </div>
        </div>

    paymentPending = () => this.state.error ?
        <div>
            <FontAwesomeIcon className={"payment-error"} icon={faTimes}/>
            <h3>Wystąpił błąd systemu</h3>
            <h4>Proszę skontaktować się z biuro@fundacjainsideout.pl i podać numer płatności
                #{this.state.payment ? this.state.payment.id : ""}</h4>
            <div className={"payment-number"}>Płatność #{this.state.payment ? this.state.payment.id : ""}</div>
        </div>
        :
        <div>
            <LoadingIcon/>
            <h3>Oczekiwanie na potwierdzenie płatności</h3>
            <h4>Nie zamykaj okna przeglądarki</h4>
            <div className={"payment-number"}>Płatność #{this.state.payment ? this.state.payment.id : ""}</div>
        </div>

    paymentReceived = () => <div>
        <FontAwesomeIcon className={"payment-check"} icon={faCheck}/>
        <h2>Dziękujemy za uczestnictwo i wsparcie!</h2>
        <h4>Na wskazany adres e-email zostało wysłane potwierdzenie</h4>
        {this.state.resent != null ?
            this.resendConfirmation()
            : <span className={"resend-link"} onClick={this.resend}>Kliknij, aby wysłać ponownie</span>}
    </div>

    paymentRejected = () => <div>
        <FontAwesomeIcon className={"payment-error"} icon={faTimes}/>
        <h2>Płatność została odrzucona</h2>
        <h4>Prosimy o ponowienie płatności za pomocą linka z wiadomości e-mail.</h4>
    </div>

    resendConfirmation = () => {
            if (this.state.resent) {
                return this.state.resentError ?
                    <span>Wiadomość została niedawno wysłana, spróbuj ponownie później</span>
                    : <span><FontAwesomeIcon icon={faCheck} /> Wysłano</span>
            }
            return <LoadingIcon/>
    }
}


export default withRouter(Confirmation)