import React, {Component} from 'react'
import './App.css'

class NotFound extends Component {
    render = () => {
        return <div className={"loading-placeholder"}>
            <p>Adres nieprawidłowy</p>
        </div>
    }
}

export default NotFound