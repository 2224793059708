class InsideOutEvent {
    constructor(id, name, date, price, products) {
        this.id = id
        this.name = name
        this.date = date
        this.price = price
        this.products = products
    }
}

export default InsideOutEvent