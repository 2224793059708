import React, {Component} from 'react'
import './Admin.css'
import {withRouter} from "react-router-dom"
import User from './datasource/User'
import Login from './Login'
import Menu from './Menu'
import Toolbar from '../components/Toolbar'

class Admin extends Component {
    state = {
        user: null,
        option: null
    }

    componentDidMount = () => {
        User.fetchCurrent()
            .then((user) => {
                this.setState({
                    user
                })
            })
            .catch(() => {
                this.setState({
                    user: false
                })
            })
    }

    render = () => {
        if (this.state.user == null) {
            return <div className={"loading-placeholder"}>
                <p>Ładowanie...</p>
            </div>
        }

        return this.page()
    }

    setOption = (option = null) => {
        this.setState({ option })
    }

    page = () => <div className={"inside-out-form-admin"}>
        <header className="inside-out-form-admin-header">
            <h1 className={"inside-out-form-admin-title"}>
                Panel zarządzania
            </h1>
        </header>
        <Toolbar
            goBackLabel={""}
            currentLabel={this.state.option != null ? this.state.option.readableName : ""}
            hidden={this.state.option == null}
            goBack={() => this.setOption(null)}
        />
        <div className={"inside-out-form-body"}>
            {this.state.user ? this.panel() : <Login setUser={this.setUser}/>}
        </div>
    </div>

    panel = () => {
        const option = this.state.option
        if (option == null) {
            return <Menu setOption={this.setOption} setUser={this.setUser} />
        } else {
            return <this.state.option />
        }
    }

    setUser = (user) => {
        this.setState({
            user
        })
    }
}

export default withRouter(Admin)
