import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import NotFound from './NotFound'
import Confirmation from './Confirmation'
import Sorry from './Sorry'
import ManagePersonalData from './ManagePersonalData'
import Admin from './admin/Admin'
import Continue from './Continue'

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path={"/apply/:id"}>
                    <App/>
                </Route>
                <Route path={"/thankyou/:id"}>
                    <Confirmation/>
                </Route>
                <Route path={"/continue/:id"}>
                    <Continue/>
                </Route>
                <Route path={"/sorry"}>
                    <Sorry/>
                </Route>
                <Route path={"/managedata/:id"}>
                    <ManagePersonalData/>
                </Route>
                <Route path={"/system/manager"}>
                    <Admin/>
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
