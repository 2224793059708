import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import './Sorry.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarTimes} from '@fortawesome/free-solid-svg-icons'

class Sorry extends Component {
    state = {}

    render = () => <div className={"sorry-placeholder"}>
        <FontAwesomeIcon icon={faCalendarTimes}/>
        <h2>Niestety, zapisy już się skończyły</h2>
        <p>Może sprawdzisz inne wydarzenia <a href="https://fundacjainsideout.pl">na naszej stronie</a>?</p>
    </div>
}

export default withRouter(Sorry)