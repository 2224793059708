import React, {Component} from 'react'
import sign from '../../assets/insideout_sign.png'
import './InsideOutSign.css'

class InsideOutSign extends Component {
    render = () => {
        return <div className={"inside-out-sign-wrapper"}>
            <img className={"inside-out-sign"} src={sign}  alt={"Znak Fundacji InsideOut"}/>
        </div>
    }
}

export default InsideOutSign