export function validateAll(form) {
    return Object.keys(form).reduce((result, key) => {
        if (typeof form[key].value != "undefined" && typeof form[key].validation == "function") {
            form[key].correct = form[key].validation(form[key].value)
            return result && form[key].correct
        }
        return result
    }, true)
}

export function emailValidation(email) {
    return notEmpty(email) && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export function alwaysTrue() {
    return true
}

export function notEmpty(value) {
    return typeof value != "undefined" && !!value
}

export function oneOf(values) {
    return (value) => notEmpty(value) && values.includes(value)
}