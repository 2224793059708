import React, {Component} from 'react'
import './GDPRInfo.css'

class GDPRInfo extends Component {
    render = () => {
        return <div className={"gdpr-qa"}>
            {this.dataStorageReason()}
            <p>Kto jest administratorem Pani/Pana danych osobowych?</p>
            <p>Dane osobowe są zbierane przez Fundację Inside Out z siedzibą w Lublinie, ul Relaksowa
                4/105</p>
            <p>Komu Pani/Pana dane będą udostępnione?</p>
            <p>Pani/Pana dane zostaną udostępnione jedynie PayPro SA z siedzibą w Poznaniu, ul. Kanclerska 15
                w celu przeprowadzenie transakcji płatniczej drogą elektroniczną.</p>
            <p>Przez jaki okres dane będą przechowywane?</p>
            <p>Pani/Pana dane będą przechowywane do momentu otrzymania od Pani/Pana sprzeciwu na adres
                e-mail: rodo@fundacjainsideout.pl</p>
            <p>Jakie ma Pani/Pan uprawnienia w związku z przetwarzaniem danych osobowych?</p>
            <p>Posiada Pani/Pan prawo do:</p>
            <ul>
                <li>żądania od Administratora dostępu do swoich danych osobowych, ich sprostowania, usunięcia
                    lub ograniczenia przetwarzania danych osobowych (zgodnie z art. 15, 16, 17, 18 RODO),
                </li>
                <li>wniesienia skargi do organu nadzorczego - Prezesa Urzędu Ochrony danych Osobowych,</li>
                <li>wniesienia sprzeciwu wobec przetwarzania (zgodnie z art. 21 RODO).</li>
            </ul>
            <p>Pozostałe informacje</p>
            <ul>
                <li>Pani/Pana dane osobowe nie podlegają zautomatyzowanemu podejmowaniu decyzji, w tym
                    profilowaniu
                </li>
                <li>Pani/Pana dane nie będą przekazywane poza Europejski Obszar Gospodarczy. Dane nie będą
                    również przekazywane organizacjom międzynarodowym.
                </li>
            </ul>
        </div>
    }

    dataStorageReason = () => {
        if (this.props.insideOutEvent) {
            return <div>
                <p>W jakim celu zbieramy Pani/Pana osobowe i jakie dane zbieramy?</p>
                <p>W celu przeprowadzenia rekrutacji na webinarium "{this.props.insideOutEvent ? this.props.insideOutEvent.name : ""}" w
                    dniu {this.props.insideOutEvent ? this.props.insideOutEvent.date : ""}.</p>
                <p>Jeżeli wyrażą Państwo zgodę w powyższym podpunkcie, dopiszemy Państwa mail do listy
                    mailingowej w celu wysyłania informacji o wydarzeniach i działaniach w ramach projektu w
                    formie newslettera. Będziemy przetwarzali Pani/Pana imię, nazwisko, adres e-mail.</p>
            </div>
        } else {
            return <div>
                <p>W jakim celu zbieramy Pani/Pana osobowe i jakie dane zbieramy?</p>
                <p>W celu przeprowadznia rekrutacji na webinarium bądź przesyłania informacji
                    o wydarzeniach i działaniach w ramach projektu w formie newslettera.
                    Będziemy przetwarzali Pani/Pana imię, nazwisko, adres e-mail, numer telefonu i podany adres.</p>
            </div>
        }
    }
}

export default GDPRInfo