import DatasourceException from '../../datasource/DatasourceException'
import {apiUrl} from '../../datasource/urls'

class Events {
    static fetchAll = () => fetch(`${apiUrl}admin/event_list.php`, {
        credentials: "include"
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            }

            throw new DatasourceException("not logged")
        })
}

export default Events