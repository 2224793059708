import React, {Component} from 'react'
import './Toolbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

class Toolbar extends Component {
    render = () => {
        return <div
            className={"toolbar " + (this.props.hidden ? "hide" : "show")}
            onClick={() => this.props.goBack()}
        >
            <span className={"go-back-button"}>
                <FontAwesomeIcon className={"chevron-left"} icon={faChevronLeft} />
                {this.props.goBackLabel}
            </span>
            <span className={"currently-chosen"}>{this.props.currentLabel}</span>
        </div>
    }
}

export default Toolbar