import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import './App.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelopeOpenText} from '@fortawesome/free-solid-svg-icons'
import InsideOutLogo from './components/elements/InsideOutLogo'
import EventDatasource from './datasource/EventDatasource'

class Continue extends Component {

    state = {
        showBody: true,
        event: null
    }

    setCertificateOption = (option) => {
        const changeState = (statesQueue) => {
            this.setState(statesQueue.shift(), () => {
                if (statesQueue.length > 0) {
                    setTimeout(() => {
                        changeState(statesQueue)
                    }, 200)
                }
            })
        }

        changeState([{showBody: false}, {option: option}, {showBody: true}])
    }

    componentDidMount = () => {
        const id = this.props.match.params.id

        EventDatasource.fetchEvent(id)
            .then((event) => {
                this.setState({
                    event
                })
            })
            .catch(() => {
                this.setState({
                    wrongEvent: true
                })
            })
    }

    render = () => {
        return (
            this.state.event == null ? <div className={"loading-placeholder"}>
                <p>Ładowanie...</p>
            </div> : this.eventPage()
        )
    }

    eventPage = () =>
        <div className="inside-out-form">
            <header className="inside-out-form-header">
                <h1 className={"inside-out-form-title"}>
                    Zgłoszenie o wydanie zaświadczenia
                </h1>
                <h2 className={"inside-out-form-event"}>
                    {this.state.event.name}
                </h2>
            </header>
            <div className={"inside-out-form-body payment-confirmation show"}>
                <InsideOutLogo/>
                <div>
                    <FontAwesomeIcon className={"payment-confirm"} icon={faEnvelopeOpenText}/>
                    <h2>Potwierdź adres e-mail</h2>
                    <h4>Aby otrzymać zaświadczenie, kliknij w link w wiadomości e-mail.</h4>
                    <h4>Jeżeli wiadomość nie przyszła, poszukaj jej w folderze <i>SPAM</i> bądź <i>Oferty</i>.</h4>
                    <div className={"payment-number"}>Jeżeli w ciągu 30 minut nie otrzymasz żadnej wiadomości, to uzupełnij ponownie formularz.</div>
                </div>
            </div>
        </div>

}

export default withRouter(Continue)