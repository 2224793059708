import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import GDPRInfo from './components/elements/GDPRInfo'
import './App.css'
import './components/Form.css'
import './ManagePersonalData.css'
import PersonalDataDatasource from './datasource/PersonalDataDatasource'
import InsideOutLogo from './components/elements/InsideOutLogo'
import Checkbox from './components/form/Checkbox'
import SubmitButton from './components/form/SubmitButton'

class ManagePersonalData extends Component {
    state = {
        email: '',
        noData: false,
        marketing: false
    }

    componentDidMount = () => {
        PersonalDataDatasource.fetchData(this.props.match.params.id)
            .then((data) => {
                this.setState({
                    personalData: data,
                    email: data["email"]
                })
            })
            .catch(() => {
                this.setState({
                    noData: true
                })
            })
    }

    render = () => {
        return <div className="inside-out-form data-management-form">
            <header className="inside-out-form-header">
                <h1 className={"inside-out-form-title"}>
                    {this.state.email}
                </h1>

                <h2 className={"inside-out-form-event"}>
                    Zarządzanie danymi
                </h2>
            </header>
            <div className={"inside-out-form-body show"}>
                <InsideOutLogo/>
                {this.state.noData ? this.noData() : this.dataManagement()}
            </div>
        </div>
    }

    noData = () => {
        return <div>
            <h2>Dane nie są przetwarzane przez nasz system</h2>
            <h4>Oznacza to, że użytkownik usunął dane z naszego systemu.</h4>
        </div>
    }

    submit = () => {
        PersonalDataDatasource.saveMarketing(this.state.personalData["id"], this.state.marketing, this.state.personalData["change_token"])
            .then(() => {
                window.location.reload(true)
            })
            .catch(() => {
                window.location.reload(true)
            })
    }

    deleteData = () => {
        PersonalDataDatasource.deleteData(this.state.personalData["id"], this.state.personalData["change_token"])
            .then(() => {
                window.location.reload(true)
            })
            .catch(() => {
                window.location.reload(true)
            })
    }

    setMarketing = (value) => {
        const personalData = this.state.personalData
        if (personalData) {
            personalData["has_marketing"] = value
        }

        this.setState({
            personalData,
            marketing: value
        })
    }

    dataManagement = () => {
        return <div>
            <form>
                <div className={"form-column form-column-stretched"}>
                    {
                        this.state.personalData ?
                            <div className={"form-section data-management-section"}>
                                <h3>Dane przetwarzane przez nasz system</h3>
                                <ul className={"managed-data"}>
                                    <li>Imię i nazwisko</li>
                                    {this.state.personalData["has_phone"] ? <li>Numer telefonu</li> : ""}
                                    {this.state.personalData["has_address"] ?
                                        <li>Ulica, numer domu i mieszkania, kod pocztowy i miasto</li> : ""}
                                    {this.state.personalData["has_certificates"] ? <li>Wystawione zaświadczenia</li> : ""}
                                </ul>
                                <p className={"registration-date-info"}>Data pierwszego wprowadzenia do systemu: {this.state.personalData["registration_date"]}</p>
                            </div>
                            : ""
                    }

                    <div className={"form-section"}>
                        <p className={"form-section-description"}>Zgoda marketingowa</p>
                        <Checkbox id={"marketing-campaign"}
                                  label={"Chcę otrzymać od Fundacji Inside Out newslettera z inspiracjami informacjami o wydarzeniach"}
                                  correct={true}
                                  checked={this.state.personalData ? this.state.personalData["has_marketing"] : false}
                                  setValue={this.setMarketing}
                        />
                    </div>

                    <div className={"form-section"}>
                        <SubmitButton
                            label={"Zapisz zmiany"}
                            onSubmit={this.submit}
                        />
                    </div>

                    <div className={"form-section"}>
                        <p className={"form-section-description"}>Przetwarzanie danych osobowych</p>
                        <GDPRInfo/>
                    </div>


                    <div className={"form-section data-removal"}>
                        <p className={"form-section-description"}>Usuwanie danych osobowych</p>
                        <p><strong>Uwaga:</strong> Usunięcie danych osobowych z naszej bazy danych uniemożliwi dalszą
                            wysyłkę wiadomości oraz utrudni proces reklamacji w przypadku problemów z płatnością</p>
                        <p>Jeżeli zaświadczenie nie zostało wydane, to po usunięciu <strong>nie zostanie wysłane </strong>
                            i nie będzie możliwości zwrotu pieniędzy.</p>
                        <p><strong>Przed wykonaniem tej akcji upewnij się, że wszystkie płatności zostały zaksięgowane,
                            a zaświadczenia wydane!</strong></p>
                        <span className={"delete-data-link"} onClick={this.deleteData}>Usuń dane</span>
                    </div>
                </div>
            </form>
        </div>
    }

}

export default withRouter(ManagePersonalData)