import React, {Component} from 'react'
import './InputField.css'

class InputField extends Component {

    state = {
        dirty: false,
        value: ''
    }

    activate = () => {
        this.setState({
            dirty: true
        })
    }

    blur = () => {
        if (!this.state.value) {
            this.setState({
                dirty: false
            })
        }
    }

    changeValue = (event) => {
        if (this.disabled()) return

        this.setState({
            value: event.target.value
        })
        if (this.props.setValue) {
            this.props.setValue(event.target.value)
        }
    }

    disabled = () => typeof this.props.disabled != "undefined" ? this.props.disabled : false

    render = () => {
        const classes = [
            "input-field-wrapper",
            (this.state.dirty ? "dirty" : ""),
            (this.props.required ? "required" : ""),
            (!this.props.correct ? "fix-me" : "")
        ]

        return <div className={classes.join(" ")}>
            <div className={"label"}>{this.props.label}<span className={"required-indicator"}>*</span></div>
            <input type={ this.props.type ? this.props.type : "text" }
                   onFocus={this.activate}
                   onBlur={this.blur}
                   onChange={this.changeValue}
                   value={this.state.value}
                   required={this.props.required}
                   disabled={this.disabled()}
            />
        </div>
    }
}

export default InputField